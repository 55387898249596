<template>
	<w-text-input
		v-model="copiedValue"
		:append-icon="showPassword ? 'visibility' : 'visibility_off'"
		:label="label"
		prepend-icon="lock"
		:regex="regex"
		:required="required"
		:type="showPassword ? 'text' : 'password'"
		:disabled="disabled"
		@click:append="onClickAppend"
		v-on="$listeners"
	/>
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-password-input
 *
 * @since 0.3.2
 */
export default {
	name: 'WPasswordInput',
	mixins: [Validator],
	props: {
		label: {
			required: false,
			type: String,
			default: ''
		},
		regex: {
			required: false,
			type: String,
			default: null
		},
		required: {
			required: false,
			type: Boolean,
			default: true
		},
		value: {
			required: false,
			type: String,
			default: ''
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			showPassword: false
		}
	},
	computed: {
		copiedValue: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	},
	methods: {
		onClickAppend: function () {
			this.showPassword = !this.showPassword
		}
	}
}
</script>
